import produce from 'immer';
import { isEmpty, not } from 'ramda';
import {
  IFetchSearchStart,
  IFetchSearchSuccessAction,
  IFetchSuggestionsSuccessAction,
  IForceToggleQuickFilter,
  IHighlightMapMarker,
  IMapMoved,
  IResetSearchFilters,
  ISearchSourceAction,
  ISetFiltersTab,
  ISetLastRowPositionAction,
  ISetLocationEndAction,
  ISetPerformedSearchAction,
  ISetPopularVenueTypes,
  ISetPreset,
  ISetSearchBarFormFieldAction,
  ISetSearchLocationAutocompleteText,
  ISetSearchLocationUnknown,
  ISetSessionSuppliersRedirectTarget,
  ISetSortingOption,
  IShowAllSupplierTypes,
  IToggleMapView,
  IToggleSearchFilterAccordionAction,
  IToggleSearchModal,
  IUpdateFiltersCountAction,
  IUpdateSearchCategoryAction,
  IUpdateSearchFiltersAction,
  SearchActionTypes,
} from 'lib/search/action-types';
import { SearchLocationStorage } from 'lib/search/search-location-storage';
import {
  formatFiltersFromSearch,
  getFilterCount,
  isSearchResultsOrLanding,
} from 'lib/search/utils';
import { Action, IReducersImmer, ISearchModalOptions, ISearchState } from 'lib/types';
import { deepMerge, getReducerActions, isSupplierPage } from 'lib/utils';
import { removeMarketFromPath } from 'lib/utils/url';
import FilterSections from './filter-sections';
import formatArea from './utils/format-prediction-area';

const SearchModalOptions: ISearchModalOptions = {
  activeTab: 'byLocation',
  isVenueSearch: false,
};

const initialState: ISearchState = {
  categoryChanged: false,
  filters: {
    data: {},
    status: 'notLoaded',
  },
  filtersInitial: {
    data: {},
    status: 'notLoaded',
  },
  filtersSections: { ...FilterSections },
  filtersTab: 'filters',
  firstLoad: true,
  filtersCount: 0,
  filtersInitialCount: 0,
  sortOption: 'favourites',
  preset: '',
  list: [],
  prevList: [],
  noResults: false,
  searchModalOptions: { ...SearchModalOptions },
  totalResults: 0,
  searchResultCount: [],
  suggestions: [],
  suggestionsLoading: false,
  suggestionsLoaded: false,
  filtersShown: false,
  currentResultsCount: 0,
  loaded: false,
  filterAccordions: [],
  distanceSortDisabled: false,
  lastRowPosition: 0,
  locationData: {},
  performed: { mockCouplesNumber: '', performedSearch: false },
  map: {
    mapMovedCoords: {},
  },
  isMapView: false,
  facetsContent: null,
  searchSource: null,
  searchModal: null,
  searchLocation: {
    status: 'uninitialized',
  },
  showAllSupplierTypes: false,
  popularVenueTypes: null,
  currentSessionSupplierNavigateTarget: null,
  request: {
    slug: 'venue',
    area: '',
    sort: 'favourites',
    page: 1,
  },
  results: {
    status: 'notLoaded',
  },
  // Name of quick filter that is
  // force opened without user clicking on it.
  // E.g. in wedding estimate price banner when user
  // clicks on cta it will open Price quick filter.
  // Only one quick filter makes sense to be
  // force opened at a time.
  quickFilterForceOpened: null,
};

const reducers: IReducersImmer<ISearchState> = (draft) => ({
  [SearchActionTypes.FETCH_SEARCH_SUCCESS]: (action: IFetchSearchSuccessAction) => {
    const {
      distanceSortDisabled,
      facetsContent,
      fields,
      filters,
      forceReplace,
      results,
      totalResults,
    } = action.payload;

    const formattedFilters = formatFiltersFromSearch(filters);

    const mergedFilters = deepMerge(draft.filters.data, formattedFilters);

    const list = !forceReplace ? [...draft.list, ...results] : results;
    const newState: ISearchState = {
      ...draft,
      prevList: draft.list,
      list,
      totalResults,
      noResults: isEmpty(results),
      currentResultsCount: results.length,
      filters: {
        data: mergedFilters,
        status: 'loaded',
      },
      filtersInitial: {
        data: mergedFilters,
        status: 'loaded',
      },
      filtersInitialCount: getFilterCount(formattedFilters),
      loaded: true,
      distanceSortDisabled,
      facetsContent,
      results: {
        status: 'loaded',
        fields: { ...fields, page: fields.page || 1 },
      },
      // If map view is active, change the clicked marker to the first one in the list
      ...(list.length &&
        draft.isMapView && {
          map: {
            ...draft.map,
            clickedMarkerId: list[0].id,
          },
        }),
    };
    return newState;
  },

  [SearchActionTypes.RESET_LOCATION_DATA]: () => {
    draft.locationData = {};
  },

  [SearchActionTypes.FETCH_SEARCH_START]: (action: IFetchSearchStart) => {
    draft.loaded = false;
    draft.request = {
      ...action.payload,
      slug: action.payload.slug,
      area: action.payload.area,
      formattedArea: action.payload.area ? formatArea(action.payload.area) : undefined,
    };
  },

  [SearchActionTypes.FETCH_SEARCH_SUGGESTIONS]: () => {
    draft.suggestionsLoading = true;
  },

  [SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_SUCCESS]: (
    action: IFetchSuggestionsSuccessAction,
  ) => {
    draft.suggestionsLoading = false;
    draft.suggestionsLoaded = true;
    draft.suggestions = action.payload;
  },

  [SearchActionTypes.RESET_SEARCH_SUGGESTIONS]: () => {
    draft.suggestionsLoaded = false;
    draft.suggestions = [];
  },

  [SearchActionTypes.UPDATE_SEARCH_CATEGORY]: (action: IUpdateSearchCategoryAction) => {
    draft.request.slug = action.payload.type;
    draft.categoryChanged = true;
  },

  [SearchActionTypes.UPDATE_FILTERS_COUNT]: (action: IUpdateFiltersCountAction) => {
    draft.filtersCount = action.payload.count;
  },

  [SearchActionTypes.RESET_SEARCH_FILTERS]: (action: IResetSearchFilters) => {
    if (action.payload?.updateInitial) {
      draft.filtersInitial = { ...draft.filters };
      draft.filtersInitialCount = draft.filtersCount;
    }
    draft.filters = {
      status: 'loaded',
      data: {},
    };
    draft.filtersCount = 0;
    draft.firstLoad = true;
  },

  [SearchActionTypes.RESET_SEARCH_CONDITIONS]: () => {
    draft.filtersInitial = { ...draft.filters };
    draft.filtersInitialCount = draft.filtersCount;
    draft.filters = {
      status: 'notLoaded',
      data: {},
    };
    draft.filtersCount = 0;
    draft.firstLoad = true;
    draft.preset = '';
    // clear map moved coordinates
    draft.map.mapMovedCoords = {};
  },

  [SearchActionTypes.SET_SEARCHBAR_FORM_FIELD]: ({ payload }: ISetSearchBarFormFieldAction) => {
    draft.request.area = payload;
  },

  [SearchActionTypes.TOGGLE_FILTERS]: () => {
    draft.filtersShown = not(draft.filtersShown);
    draft.filtersTab = 'filters';
  },

  [SearchActionTypes.SET_SEARCH_LOCATION_END]: (action: ISetLocationEndAction) => {
    draft.searchLocation = action.payload;
    SearchLocationStorage.set(draft.searchLocation);
  },
  [SearchActionTypes.SET_SEARCH_LOCATION_UNKNOWN]: (action: ISetSearchLocationUnknown) => {
    if (draft.searchLocation.status === 'initialized') {
      draft.searchLocation.draft.unknownLocation = action.payload;
    }
  },
  [SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT]: (
    action: ISetSearchLocationAutocompleteText,
  ) => {
    const searchText = action.payload.searchText;
    if (draft.searchLocation.status === 'initialized') {
      draft.searchLocation.draft.searchText = searchText;
      draft.searchLocation.draft.searchType = 'autocomplete';
      SearchLocationStorage.set(draft.searchLocation);
    }
  },

  [SearchActionTypes.CLEAR_ALL_SEARCH]: () => {
    if (!draft.map.mapMovedCoords) {
      draft.list = [];
    }
    draft.results = {
      status: 'notLoaded',
    };
  },

  [SearchActionTypes.RESET_SEARCH_MODAL_OPTIONS]: () => {
    draft.searchModalOptions = { ...SearchModalOptions };
  },
  // prepare filters for Elastic
  [SearchActionTypes.UPDATE_SEARCH_FILTERS]: (action: IUpdateSearchFiltersAction) => {
    const { payload } = action;
    const filtersToUpdate = Array.isArray(payload) ? payload : [payload];

    filtersToUpdate.forEach((filter, index) => {
      const { name, value, clearPreviousFilters = false } = filter;

      /** If the filter has a section (name), it's value should be in an
       object's property */
      const fullValue = name ? { [name]: value } : value;

      /** If clearPreviousFilters flag was passed, currently set filters
       should be cleared before new ones are applied.
       If not, it's merged to current state of filters.
       */

      draft.filters = {
        data:
          clearPreviousFilters && index === 0
            ? fullValue
            : deepMerge(draft.filters.data, fullValue),
        status: 'loaded',
      };
    });
  },

  // update initial filters on url change
  [SearchActionTypes.UPDATE_SEARCH_URL]: () => {
    draft.filtersInitial = { ...draft.filters };
    draft.filtersInitialCount = draft.filtersCount;
  },

  [SearchActionTypes.TOGGLE_SEARCH_FILTER_ACCORDION]: (
    action: IToggleSearchFilterAccordionAction,
  ) => {
    draft.filterAccordions = action.payload;
  },

  [SearchActionTypes.SEARCH_SET_LAST_ROW_POSITION]: (action: ISetLastRowPositionAction) => {
    draft.lastRowPosition = action.payload;
  },

  [SearchActionTypes.SET_SEARCH_SOURCE]: (action: ISearchSourceAction) => {
    draft.searchSource = action.payload.searchSource || null;
  },

  [SearchActionTypes.SET_PERFORMED_SEARCH]: (action: ISetPerformedSearchAction) => {
    draft.performed = action.payload.performed;
  },
  [SearchActionTypes.MAP_MARKER_HIGHLIGHTED]: ({ payload }: IHighlightMapMarker) => {
    draft.map.highlightedMarkerId = payload;
  },
  [SearchActionTypes.MAP_MARKER_CLICKED]: ({ payload }: IHighlightMapMarker) => {
    draft.map.clickedMarkerId = payload;
  },
  [SearchActionTypes.MAP_CLEAR_HIGHLIGHTED_MARKER]: () => {
    draft.map.highlightedMarkerId = undefined;
  },
  [SearchActionTypes.MAP_CLEAR_CLICKED_MARKER]: () => {
    draft.map.clickedMarkerId = undefined;
    draft.map.highlightedMarkerId = undefined;
  },
  [SearchActionTypes.MAP_MOVED]: ({ payload }: IMapMoved) => {
    draft.map.mapMovedCoords = payload;
  },
  [SearchActionTypes.TOGGLE_MAP_VIEW]: ({ payload: { showMapView } }: IToggleMapView) => {
    draft.isMapView = showMapView;

    if (showMapView) {
      // When showing map view, highlight the first marker
      if (draft.list.length) {
        draft.map.clickedMarkerId = draft.list[0].id;
      }
    } else {
      // When hiding map view, clear the clicked marker
      draft.map.clickedMarkerId = undefined;
    }
  },
  [SearchActionTypes.SET_POPULAR_VENUE_TYPES]: ({ payload }: ISetPopularVenueTypes) => {
    draft.popularVenueTypes = payload;
  },
  [SearchActionTypes.SET_SESSION_SUPPLIERS_REDIRECT_TARGET]: ({
    payload,
  }: ISetSessionSuppliersRedirectTarget) => {
    draft.currentSessionSupplierNavigateTarget = payload;
  },
  [SearchActionTypes.CLICKED_SEE_ALL_SUPPLIERS]: ({ payload }: IShowAllSupplierTypes) => {
    draft.showAllSupplierTypes = payload;
  },
  [SearchActionTypes.CLICKED_SEARCH_MODAL]: ({ payload }: IToggleSearchModal) => {
    draft.searchModal = payload;
  },
  ROUTE_CHANGE_COMPLETE: ({ payload }) => {
    const { url, query } = payload;

    const urlWithoutMarket = removeMarketFromPath(url, query.market);

    // close map view if current page is not search page or supplier page
    // and map was left open
    if (
      !(isSearchResultsOrLanding(urlWithoutMarket) || isSupplierPage(urlWithoutMarket)) &&
      draft.isMapView
    ) {
      draft.isMapView = false;
    }
  },
  [SearchActionTypes.SET_PRESET]: ({ payload }: ISetPreset) => {
    draft.preset = payload;
  },

  [SearchActionTypes.FORCE_TOGGLE_QUICK_FILTER]: ({ payload }: IForceToggleQuickFilter) => {
    const { filterName } = payload;

    draft.quickFilterForceOpened = draft.quickFilterForceOpened === filterName ? null : filterName;
  },
  [SearchActionTypes.SET_FILTERS_TAB]: ({ payload }: ISetFiltersTab) => {
    draft.filtersTab = payload;
  },
  [SearchActionTypes.SET_SORTING_OPTION]: ({ payload }: ISetSortingOption) => {
    draft.sortOption = payload;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: ISearchState = initialState, action: Action): ISearchState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
